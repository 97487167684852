import React, { useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight, faTimes } from "@fortawesome/free-solid-svg-icons"

import Icon1 from "../assets/account-management-icon-1.svg"
import Icon2 from "../assets/account-management-icon-2.svg"
import Icon3 from "../assets/account-management-icon-3.svg"
import Icon4 from "../assets/account-management-icon-4.svg"
import Icon5 from "../assets/account-management-icon-5.svg"
import Icon6 from "../assets/account-management-icon-6.svg"
import Icon7 from "../assets/account-management-icon-7.svg"
import Icon8 from "../assets/account-management-icon-8.svg"

const AccountManagement = ({ colorLight, colorDark, logo }) => {
  const accountIcon = () => {
    const icons = document.querySelectorAll(".account-management-icon > path")

    for (let i = 0; i < icons.length; i++) {
      icons[i].style.fill = colorLight + 60
    }
  }

  useEffect(() => {
    accountIcon()
  }, [colorLight])
  return (
    <div className="account-management-section">
      <div className="container">
        <div className="account-management-wrapper">
          <div className="account-management-col">
            <h2 className="title">
              Done-for-you checkout and account management in your brand
              identity.
            </h2>
            <ul className="description-holder">
              <li>
                <p>Get the best of both worlds</p>
                <p>
                  Get both blazing fast implementation and the branded
                  experience your customer deserve, served from your domain.
                </p>
              </li>
              <li>
                <p>Update brand elements instantly</p>
                <p>
                  And see your changes reflected straight away across all of
                  your Billsby modals, emails, invoices and more.
                </p>
              </li>
              <li>
                <p>If you can do it in Billsby, you can do it with the API</p>
                <p>
                  When you're ready, go even further and make some elements of
                  the journey completely customized with our API. It's not an
                  either-or choice - you can start with our simple drop-in code,
                  and then customize more of the experience as you got at your
                  own pace.
                </p>
              </li>
              <li>
                <p>Not just skin deep</p>
                <p>
                  Our customization goes far beyond colours. Want to call your
                  plans "bundles"? Sure thing. Need people to choose how many
                  'seats' they want rather than units? We've got you covered.
                  The terminology in your checkout and account management
                  journey can match what you use everywhere else.
                </p>
              </li>
            </ul>
          </div>
          <div className="account-management-col">
            <div className="menus-holder">
              <div
                className="btn-close"
                style={{
                  backgroundColor: `${colorDark ? colorDark : "#505050"}`,
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </div>
              <div
                className="logo-holder"
                style={{
                  backgroundColor: `${
                    colorLight ? colorLight + 60 : "#E3E3E3"
                  }`,
                }}
              >
                {logo ? <img src={logo} alt="logo"/> : <h2>Your Logo</h2>}
              </div>
              <ul className="menus-wrapper">
                <li>
                  <Icon1 className="account-management-icon" />
                  View and change plan
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    style={{ color: `${colorDark ? colorDark : "#505050"}` }}
                  />
                </li>
                <li>
                  <Icon2 className="account-management-icon" />
                  Change personal details
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    style={{ color: `${colorDark ? colorDark : "#505050"}` }}
                  />
                </li>
                <li>
                  <Icon3 className="account-management-icon" />
                  Change address
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    style={{ color: `${colorDark ? colorDark : "#505050"}` }}
                  />
                </li>
                <li>
                  <Icon4 className="account-management-icon" />
                  Update payment Details
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    style={{ color: `${colorDark ? colorDark : "#505050"}` }}
                  />
                </li>
                <li>
                  <Icon5 className="account-management-icon" />
                  Manage allowances
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    style={{ color: `${colorDark ? colorDark : "#505050"}` }}
                  />
                </li>
                <li>
                  <Icon6 className="account-management-icon" />
                  Manage add-ons
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    style={{ color: `${colorDark ? colorDark : "#505050"}` }}
                  />
                </li>
                <li>
                  <Icon7 className="account-management-icon" />
                  View billing history
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    style={{ color: `${colorDark ? colorDark : "#505050"}` }}
                  />
                </li>
                <li>
                  <Icon8 />
                  Cancel plan
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    style={{ color: `${colorDark ? colorDark : "#505050"}` }}
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountManagement
